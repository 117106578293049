<template>
    <v-container class="py-8 px-6 pl-12" fluid>

        <v-card outlined style="border: 0px solid white" class="mt-n10">
            <v-card-text class="mt-n3">
                <div class="cont-title" style="float: left;">ARS 메뉴</div>
            </v-card-text>
        </v-card>

        <v-row class="mt-16">
            <v-col class="mb-0">
                <table style="width:100%;">
                    <tr>
                        <td style="width:349px; vertical-align:top;">
                            <v-card flat style="width:349px;" class="">
                                <tile-menus @addMenu="openAddPopup" @openMenuOrder="openMenuOrder" @selectMenu="selectMenu"></tile-menus>
                            </v-card>
                        </td>
                        <td style="vertical-align:top;">
                            <tile-detail-desc v-if="!curMenu"></tile-detail-desc>
                            <v-row v-else>
                                <v-col class="pt-0 mt-0 pl-7">
                                    <tile-detail :ref="'menu_detail'"></tile-detail>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </table>
            </v-col>
        </v-row>

        <v-dialog v-model="modal" persistent max-width="600" @keydown.esc="closePopup()">
            <v-flex v-if="dialogMode === 'add'">
                <popup-menu-add v-on:submit="submitAdd" v-on:cancel="closePopup"></popup-menu-add>
            </v-flex>
            <v-flex v-if="dialogMode === 'order'">
                <popup-menu-order v-on:submit="submitOrder" v-on:cancel="closePopup"></popup-menu-order>
            </v-flex>
        </v-dialog>

    </v-container>
</template>
<script>
import {mapGetters} from 'vuex'


export default {
    name: 'svc_voice_scn',
    components: {
        'tileMenus': () => import('@/components/svc/voice_scn/tile_menus.vue'),
        'tileDetail': () => import('@/components/svc/voice_scn/tile_detail.vue'),
        'tileDetailDesc': () => import('@/components/svc/voice_scn/tile_detail_desc.vue'),
        'popupMenuAdd': () => import('@/components/svc/voice_scn/popup_menu_add.vue'),
        'popupMenuOrder': () => import('@/components/svc/voice_scn/popup_menu_order.vue'),
    },
    data: () => ({
        modal: false,
        dialogMode: 'none'

    }),
    computed: {
        ...mapGetters({
            curMenu: 'svcVoiceScn/getSelectedMenu',
        }),

    },
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'svc_void_scn')

        this.reloadMenuList()
    },
    methods: {
        reloadMenuList() {
            this.$store.dispatch('svcVoiceScn/getMenuList', {})
        },
        openAddPopup() {
            this.dialogMode = 'add'
            this.modal = true
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'none'
        },
        submitAdd() {
            this.reloadMenuList()
            this.closePopup()
        },
        delMenu() {

        },
        selectMenu() {
        },
        openMenuOrder(item) {
            this.dialogMode = 'order'
            this.modal = true
        },
        submitOrder() {
            this.reloadMenuList()
            this.closePopup()
        }
    }
}
</script>

